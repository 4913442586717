<template>
  <Layout ref="main">
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card" id="card_section">
          <div class="card-title p-3 py-2 border-bottom">
            <div class="d-flex align-items-center">
              <div class="me-2">
                <i class="bi bi-bar-chart display-6 text-primary"></i>
              </div>
              <div>
                <h4 class="fw-medium font-size-20 text-dark mb-0 lh-1">
                   {{ title }} 
                </h4>
              </div>
              <div class="ms-auto">
                <router-link v-if="supportBackPage.includes(prevRoute.name)" :to="{ name: prevRoute.name }" class="btn btn-outline-info fw-medium me-1">
                  <i class="mdi mdi-arrow-left font-size-16"></i> Back
                 </router-link>
                <!-- <router-link v-else :to="{ name: 'contracts', query: { reseller: prevRoute.query.reseller || 'all', merchant: prevRoute.query.merchant || 'all', page: prevRoute.query.page || 1 } }" class="btn btn-outline-info fw-medium me-1">
                  <i class="mdi mdi-arrow-left font-size-16"></i> Back
                 </router-link> -->
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-12 col-lg-6 col-xl-3" v-if="account_type!=='merchant'">
                  <label class="form-label mb-1">Reseller</label>
                  <multiselect v-model="selectedReseller" :options="reseller" label="account_name" class="mb-3" placeholder="Select Reseller" :close-on-select="true" :allow-empty="false" deselect-label="" :show-on-select="true" :disabled="loading" @input="getMerchantContract()">
                  <template slot="singleLabel" slot-scope="props">
                    <span v-if="props.option.account_db_code!=='all'">
                      <span class="option__title">
                        <span v-if="props.option.reseller_level!=='' && account_type=='admin'">
                          <span class="badge bg-info font-size-12" v-if="props.option.reseller_level=='0'"><i class="bi bi bi-house-door"></i></span>
                          <span class="badge bg-info font-size-12" v-else>Level {{ props.option.reseller_level }}</span>
                        </span>

                        {{ props.option.account_name }} 
                      </span>
                    </span>
                    <span v-else>
                      {{ props.option.account_name }}
                      <span v-if="reseller.length>1">({{ reseller.length-1 }})</span>
                        
                    </span>
                  </template>
                  <template slot="option" slot-scope="props">
                    <span v-if="props.option.account_db_code!=='all'">
                      <span class="option__title">
                        <span v-if="props.option.reseller_level!=='' && account_type=='admin'">
                          <span class="badge bg-info font-size-12" v-if="props.option.reseller_level=='0'"><i class="bi bi bi-house-door"></i></span>
                          <span class="badge bg-info font-size-12" v-else>Level {{ props.option.reseller_level }}</span>
                        </span>
                          {{ props.option.account_name }} 
                      </span>
                    </span>
                    <span v-else>
                      {{ props.option.account_name }} <span v-if="reseller.length>1">({{ reseller.length-1 }})</span>
                    </span>
                  </template>
                  <span slot="noResult">Oops! No reseller found. </span>
                </multiselect>
              </div>
              <div class="col-12 col-lg-6 col-xl-3">
                <label class="form-label mb-1">Merchant</label>
                <multiselect
                  ref="select"
                  v-model="selectedmerchantContract"
                  :options="merchantContract"
                  label="account_name"
                  class="mb-3"
                  placeholder="Select"
                  :close-on-select="true"
                  :allow-empty="false"
                  deselect-label=""
                  :show-on-select="true"
                  :disabled="disabled"
                  @open="isOpen = true"
                  @close="isOpen = false"
                  @input="handlePageChange(1)"
                >
                <template slot="singleLabel" slot-scope="props">
                  <span v-if="props.option.contract_id!==''">
                    <span v-if="props.option.contract_status=='unavailable'" class="text-warning me-1">&#9679;</span>
                    <span v-else-if="props.option.contract_status=='available'" class="text-success me-1">&#9679;</span>
                    <span class="option__title"> {{ props.option.account_name }}
                    </span>
                  </span>
                  <span v-else>{{ props.option.contract_name }}  </span>
                </template>
                <template slot="option" slot-scope="props">
                  <span v-if="props.option.contract_id!==''">
                    <span v-if="props.option.contract_status=='unavailable'" class="text-warning me-1">&#9679;</span>
                    <span v-else-if="props.option.contract_status=='available'" class="text-success me-1">&#9679;</span>
                    <span class="option__title">  {{ props.option.account_name }}
                    </span>
                  </span>
                  <span v-else>{{ props.option.contract_name }}</span>
                </template>
                <span slot="noResult">Oops! No Contract found. </span>
                </multiselect>
                
              </div>
              <div class="col-12 col-md col-xl-3 mb-0">
                <label class="form-label mb-1">Date Range</label>
                <date-picker
                  v-model="date_range"
                  type="date"
                  range
                  value-type="format"
                  format="YYYY-MM-DD"
                  placeholder="Select Date Range"
                  :disabled="disable_date"
                  :disabled-date="notBeforeToday"
                  :clearable="false"
                  :editable="false"
                  :shortcuts="shortcuts"
                  class="mb-3"
                ></date-picker>
                <!-- 
                  @change="DateChanged()" -->
              </div>

              <div class="col-12 col-md-auto col-xl-auto">
                <label class="form-label mb-1 d-md-block d-none">&nbsp;</label>
                <div class="row">
                  <div class="col-6 pe-1 col-auto d-grid">
                    <button
                      class="btn btn-light mb-3 text-nowrap"
                      type="button"
                      :disabled="disabled"
                      @click="clear();"
                    >
                      <i class="uil-redo me-1"></i> Clear
                    </button>
                  </div>
                  <div class="col-6 ps-1 col-auto d-grid">
                    <button
                      class="btn btn-info text-white mb-3 text-nowrap"
                      type="button"
                      :disabled="disabled"
                      @click="search()"
                    >
                      <i class="uil-search me-1"></i> Search
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-12 col-xl">
                <div class="float-end">
                  <div class="mb-3 ml-auto d-none">
                    <b-button
                      type="submit"
                      variant="info"
                      @click="exportData()"
                      :disabled="disableExport"
                    >
                      <i class="uil-file-export me-1"></i>
                      Export
                      <div
                        class="spinner-border spinner-border-sm text-white"
                        v-if="loadingButton"
                        role="status"
                      ></div>
                    </b-button>
                  </div>
                </div>
              </div>

              
            </div>
            <div class="row d-none" v-if="current_balance">
              <div class="col-12 col-md-auto mb-3">
                <div
                  class="d-md-flex justify-content-between bg-soft-purple p-3 rounded w-100"
                >
                  <div class="fw-medium me-2">Current Balance</div>
                  <div class="fw-medium" v-if="current_balance">
                   {{ selectedmerchantContract.contract_currency }} {{ convertCurrencyFormat(current_balance, true) || 0 }}
                  </div>
                  <div class="fw-medium" v-else>0</div>
                </div>
              </div>
              <div class="col-12 col-md-auto mb-3">
                <div
                  class="d-md-flex justify-content-between bg-soft-danger p-3 rounded"
                >
                  <div class="fw-medium me-2">Total Debit</div>
                  <div class="fw-medium" v-if="total_debit">
                    {{ selectedmerchantContract.contract_currency }}  {{ convertCurrencyFormat(total_debit,true) || 0 }}
                  </div>
                  <div class="fw-medium" v-else>0</div>
                </div>
              </div>
              <div class="col-12 col-md-auto mb-3">
                <div
                  class="d-md-flex justify-content-between bg-soft-success p-3 rounded"
                >
                  <div class="fw-medium me-2">Total Credit</div>
                  <div class="fw-medium" v-if="total_credit">
                    {{ selectedmerchantContract.contract_currency }}  {{  convertCurrencyFormat(total_credit,true) || 0 }}
                  </div>
                  <div class="fw-medium" v-else>0</div>
                </div>
              </div>
              </div>
            <div class="mb-1 d-flex align-items-center">
              <div v-if="selectedmerchantContract.account_name!==''">
                <span class="d-block">
                Merchant: 
                <span class="text-primary fw-medium">{{ selectedmerchantContract.account_name || '-' }}</span>
                </span>
              </div>
              <div class="ms-auto" v-if="create_modify_permission">
                <b-button
                  type="submit"
                  class="py-1"
                  variant="info"
                  @click="exportData()"
                  :disabled="disableExport || !returnData.length || loading"
                >
                  <i class="uil-file-export me-1"></i>
                  Export
                  <div
                    class="spinner-border spinner-border-sm text-white"
                    v-if="loadingButton"
                    role="status"
                  ></div>
                </b-button>
              </div>
            </div>
            <div
              class="table-responsive text-nowrap font-size-14 position-relative"
            >
              <!-- <div class="position-relative">
                <b-button id="tooltip-button-interactive">My tooltip is interactive</b-button>
                <b-tooltip target="tooltip-button-interactive">I will stay open when hovered</b-tooltip>
              </div> -->
              <table
                id="freeze"
                class="table mt-2 mb-0 align-middle min-width-760 font-size-11"
                :class="{'table-hover':returnData.length>0 && !loading}"
              >
                <thead class="text-uppercase font-size-11">
                  <tr>
                    <th>Merchant<br>Name</th>
                    <th>Created<br>Date</th>
                    <th class="text-center">Success <br>Rate</th>
                    <th class="text-center">Total<br>Trx.</th>
                    <th class="text-end">Total In<br>Volume</th>
                    <th class="text-center">Total Success<br>Trx.</th>
                    <th class="text-end">Total Success<br>Volume </th>
                    <th class="text-center">Total Out<br>Trx.</th>
                    <th class="text-end">Total Out<br>Volume</th>
                    <th class="text-end">Total Chg.<br>(In)</th>
                    <th class="text-end">Total Chg.<br>(Out)</th>
                    <th class="text-end">Reseller<br>Comm</th>
                    <th class="text-center">Details</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="!returnData.length && !loading">
                    <td colspan="13" height="300" class="text-center text-muted">
                      No Data Available
                    </td>
                  </tr>
                  <tr v-if="loading">
                    <td colspan="13" class="text-center text-muted" height="400">
                      <div
                        class="spinner-border text-secondary my-2"
                        role="status"
                      >
                        <span class="sr-only">Loading...</span>
                      </div>
                      <br />
                      <div>Loading...</div>
                    </td>
                  </tr>

                  <tr v-for="(value, index) in returnData" :key="index" v-show="!loading">
                    <td>{{ value.merchant_name }}</td>
                    <td>{{ value.created_date }}</td>
                    <td class="text-center">
                    
                      
                      <div class="text-success" v-if="parseFloat((value.order_count_of_success/value.order_count)*100).toFixed(2)>50">
                        <div class="block w-100 bg-soft-success badge py-1 font-size-14 fw-medium">
                          {{ convertCurrencyFormat(parseFloat((value.order_count_of_success/value.order_count)*100).toFixed(2) || 0,true) }}%
                        </div>
                      </div>
                      <div  class="text-danger" v-else-if="parseFloat((value.order_count_of_success/value.order_count)*100).toFixed(2)>0">
                        <div class="block w-100 bg-soft-danger badge py-1 font-size-14 fw-medium">
                          {{ convertCurrencyFormat(parseFloat((value.order_count_of_success/value.order_count)*100).toFixed(2) || 0,true) }}%
                        </div>
                      </div>
                      <div v-else>
                        <div class="block w-100 bg-soft-secondary badge py-1 font-size-14 fw-medium">
                          - %
                        </div>
                      </div>
                    
                    </td>
                    <td class="text-center text-purple">{{ convertCurrencyFormat(value.order_count || 0,false) }}</td>
                    <td class="text-end">
                      <div class="block w-100 bg-soft-purple badge py-1 font-size-14 fw-medium">
                        {{ convertCurrencyFormat(value.amount || 0,true) }}
                      </div>
                    </td>
                    <td class="text-center text-success">
                      {{ convertCurrencyFormat(value.order_count_of_success || 0,false) }}
                      
                    </td>
                    <td class="text-end text-success">
                      <div class="block w-100 bg-soft-success badge py-1 font-size-14 fw-medium">
                        {{ convertCurrencyFormat(value.amount_of_success || 0,true) }}
                      </div>
                    </td>
                    <td class="text-center text-danger">{{ convertCurrencyFormat(value.cash_out_count || 0,false) }}</td>
                    <td class="text-end text-danger">
                      <div class="block w-100 bg-soft-danger badge py-1 font-size-14 fw-medium">
                        {{ convertCurrencyFormat(value.cash_out_money || 0,true) }}
                      </div>
                    </td>
                    <td class="text-end">{{ convertCurrencyFormat(value.amount_of_agent_fee || 0,true) }}</td>
                    <td class="text-end">{{ convertCurrencyFormat(value.cash_out_fee || 0,true) }}</td>
                    <td class="text-end">{{ convertCurrencyFormat(value.cash_out_of_agent_fee || 0,true) }}</td>
                    <td class="text-center">
                      <button class="btn btn-outline-info px-2 py-1 font-size-15 rounded" @click="clickExtraInfo(`${value.merchant_name} (${value.created_date})`, value)" :title="`${value.merchant_db_code }`" v-if="account_type=='admin'">
                      <i class="bi bi-bar-chart"></i></button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row" v-if="returnData.length && !loading">
              <div class="col mt-3">
                <div
                  class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      hide-ellipsis
                      @change="handlePageChange"
                      v-model="currentPage"
                      :per-page="perPage"
                      :total-rows="totalData"
                    >
                    </b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <extraInfo ref="modalFunc3" :data="{ 'extraInfo': currentClickedInfo}"/>
    <Lottie
      :path="'/animate/loading_purple.json'"
      :title="'Loading'"
      :info="'Please wait...'"
      :show="showLottie"
      ref="lottieFunc"
    />
    <Common ref="commonFunc" />
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Lottie from "@/components/lottieView";
import Common from "@/components/common";
import axios from "axios";
import Swal from "sweetalert2";
import DatePicker from "vue2-datepicker";
import Multiselect from "vue-multiselect";
import extraInfo from "@/views/pages/app/modal/extraInfo";
/**
 * Page
 */
const PageTitle = "Merchant Transactions Report";
const usersDefault = {
  processedById: -1,
  processedBy: "All Users",
};
const BankDefault = {
  id: -1,
  name: "All Banks",
  countryId: 0,
  bankCode: "",
  websiteURL: "",
  isActive: 1,
  category: "",
  isSuspend: 0,
  attachment: null,
  tranCount: 0,
  successTranCount: 0,
  tranPercentage: 0,
  createdAt: "",
};
const CountryDefault = {
  id: -1,
  name: "All Countries",
  currencyId: 0,
  currencyName: "",
  timeZone: [],
};
const AllAccountDefault =
{
  "id": "",
  "account_username": "",
  "account_db_code": "all",
  "account_name": "All Resellers",
  "account_type": "",
  "up_reseller_count": 0,
  "reseller_parent": [],
  "reseller_level": "",
  "reseller_code": "",
  "merchant_count": 0
}
const MerchantDefault = {
    "status": "0",
    "payin_rate": 0,
    "contract_id": "",
    "payout_rate": 0,
    "total_payin": 0,
    "ewallet_rate": 0,
    "total_payout": 0,
    "contract_name": "Select Merchant",
    "contract_total": 0,
    "contract_status": "",
    "settlement_rate": 0,
    "contract_balance": 0,
    "total_settlement": 0,
    "contract_currency": "",
    "payin_extra_charges": 0,
    "payout_extra_charges": 0,
    "ewallet_extra_charges": 0,
    "settlement_extra_charges": 0,
    "contract_list_length_total": 0,
    "merchant_Id": "",
    "account_name": "",
    "merchant_status": "",
    "reseller_id": "",
    "reseller_name": "",
    "reseller_code": "",
    "reseller_level": 0
}
const ResellerDefault =
{
  "account_db_code": "all",
  "account_name": "All Resellers",
  "account_type": "",
  "up_reseller_count": 0,
  "reseller_parent": [],
  "reseller_level": "",
  "reseller_code": "",
  "merchant_count": 0
}
export default {
  components: {
    Layout,
    PageHeader,
    Lottie,
    Common,
    DatePicker,
    Multiselect,
    extraInfo
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },
  watch:{
    $route (to, from){
        console.log(to)
        console.log(from)
      }
  },
  page() {
    return {
      title: PageTitle,
      meta: [
        {
          name: "description",
          content: appConfig.description,
        },
      ],
    };
  },
  data() {
    return {
      currentClickedInfo:{},
      defaultName: appConfig.defaultAccountName,
      accessToken: "",
      accessEmail: "",
      accessPhone: "",
      accessUsername: "",
      account_type:'',
      showLottie: false,
      title: "",
      items: [
        {
          text: appConfig.pageTitle,
          to: "/",
        },
        {
          text: "",
          active: true,
        },
      ],
      formData: {
        apiID: "YOUR_API_ID",
        apiHash: "YOUR_API_HASH",
      },
      
      prevRoute:{
        name: "",
        query:{
           reseller:"",
           merchant:"",
           page:""
        },
      },
      
      submitted: false,
      loading: false,
      buttonLoading: [false, false, false],
      currentPage: 1,
      currencySymbol: "₹",
      selectedmerchantContract: MerchantDefault,
      merchantContract: [MerchantDefault],
      selectedCountry: CountryDefault,
      countryList: [CountryDefault],
      selectedBanks: BankDefault,
      selectedReseller: ResellerDefault,
      reseller:[ResellerDefault],
      banksList: [BankDefault],
      selectedUsers: usersDefault,
      usersList: [usersDefault],
      seletedMethod: "",
      selectedStatus: "",
      searchSettlementId: "",
      searchMerchantItemId: "",
      filterType: "created_date",
      searchAmount: "",
      perPage: 20,
      totalData: 0,
      returnData: [],
      current_balance:0,
      total_credit:0,
      total_debit:0,
      export_data: [],
      search_value: "",
      disableExport: false,
      loadingButton: false,
      date_range: null,
      disable_date: false,
      disabled: false,
      shortcuts: [],
      isOpen: false,
      currentAccount:{},
      currentMerchantInfo:{},
      supportBackPage:['payin','payout', 'pending payouts','merchants'],
      create_modify_permission:false
    };
  },
  middleware: "authentication",
  async mounted() {
    this.title = PageTitle;
    this.items[1].text = PageTitle;
    // this.$refs.main.changeVerticalTopBar("",true) *first value is back link and second is want set bg color?
    // this.$refs.main.changeVerticalTopBar("bill",true)
    // this.$refs.main.setShowFooterCert(false)
    // this.$refs.main.setPageTitle('title')
    await this.reload();
    this.accessToken = this.$refs.commonFunc.getToken();
    this.accessUsername = this.$refs.commonFunc.getUsername();
    this.date_range = this.$refs.commonFunc.last30Days();
    this.shortcuts = this.$refs.commonFunc.datePickerShortcut();
    if (this.account_type !=='merchant'){
      this.getReseller()
    }else{
      this.getMerchantContract();
    }
    //this.getMerchantContract();
    
    //this.getCountries();
    //this.getUserList();
   // await this.handlePageChange(1);
  },
  created() {},
  methods: {
    clickExtraInfo(title, object){
      this.currentClickedInfo = {title: '', info: {}}
      this.currentClickedInfo = {title: title, info: object}
      setTimeout(() => this.$refs.modalFunc3.showExtraInfoModal(), 0)
    },
    toggle () {
			this.$refs.select.$el.focus()
			setTimeout(() => {
				this.$refs.select.$refs.search.blur()
			}, 1000)
		},
		open () {
      setTimeout(() => {
        this.$refs.select.activate();
      }, 0)
		},
		close () {
      setTimeout(() => {
        this.$refs.select.deactivate();
      }, 0)
		},
    convertCurrencyFormat(value, show00) {
      if (show00 == true) {
        return this.$refs.commonFunc.convertCurrencyFormat(value);
      } else {
        // Assuming this.transactionLimit is defined in your component's data
        return this.$refs.commonFunc
          .convertCurrencyFormat(value)
          .replaceAll(".00", "");
      }
    },
    inputNumberOnly(event, parentModel) {
      const numericValue = event.target.value.replace(/[^0-9.]/g, "");
      this[parentModel] = numericValue;
    },
    countryChanged() {
      //this.selectedBanks=BankDefault
      this.handlePageChange(1);
    },
    returnBankList() {
      if (this.selectedCountry.id !== -1) {
        return this.banksList.filter(
          (bank) => bank.countryId === this.selectedCountry.id
        );
      } else {
        return this.banksList;
      }
    },
    getBankList() {
      console.log("getBankList");
      this.$Progress.start();
      this.loading = true;
      this.disabled = true;
      var bodyFormData = new FormData();
      bodyFormData.append("accessToken", this.accessToken);
      bodyFormData.append("accessUsername", this.accessUsername);
      axios({
        method: "get",
        url: "",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          var resData = response.data;
          console.log(resData.result);
          if (resData.status == 200) {
            this.selectedBanks = BankDefault;
            this.banksList = [this.selectedBanks];
            const isSuspend = [];
            resData.result.forEach((element) => {
              if (element.isSuspend === 1) {
                isSuspend.push(element);
              } else {
                this.banksList.push(element);
              }
            });
            isSuspend.forEach((element) => {
              this.banksList.push(element);
            });
          } else if (resData.status == 440) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${resData.message}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
            localStorage.clear();
            this.$router.push({
              path: "/login",
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${resData.message}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
          }
          this.loading = false;
          this.disabled = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.loading = false;
          this.disabled = false;
          this.$Progress.finish();
          //Swal.fire("Error", error, "error");
          Swal.fire({
            icon: "error",
            title: "Oops...",
            html: `${error}.`,
            confirmButtonColor: "#222",
            confirmButtonText: this.$t("siteLang.Done"),
          });
        });
    },
    getUserList() {
      console.log("getUserList");
      this.$Progress.start();
      this.loading = true;
      this.disabled = true;
      var bodyFormData = new FormData();
      bodyFormData.append("accessToken", this.accessToken);
      bodyFormData.append("accessUsername", this.accessUsername);
      axios({
        method: "post",
        url: appConfig.APIHostAdmin + 'controller/admin/getAllUserList',
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          var resData = response.data;
          if (resData.status == 200) {
            this.selectedUsers = usersDefault;
            this.usersList = [this.selectedUsers];
            resData.data.forEach((element) => {
              this.usersList.push(element);
            });
          } else if (resData.status == 440) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${resData.message}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
            localStorage.clear();
            this.$router.push({
              path: "/login",
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${resData.message}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
          }
          this.loading = false;
          this.disabled = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.loading = false;
          this.disabled = false;
          this.$Progress.finish();
          //Swal.fire("Error", error, "error");
          Swal.fire({
            icon: "error",
            title: "Oops...",
            html: `${error}.`,
            confirmButtonColor: "#222",
            confirmButtonText: this.$t("siteLang.Done"),
          });
        });
    },
    getCountries() {
      console.log("getCountries");
      this.$Progress.start();
      this.loading = true;
      this.disabled = true;
      var bodyFormData = new FormData();
      bodyFormData.append("accessToken", this.accessToken);
      bodyFormData.append("accessUsername", this.accessUsername);
      axios({
        method: "get",
        url: "",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          var resData = response.data;
          if (resData.status == 200) {
            (this.selectedCountry = CountryDefault),
              (this.countryList = [this.selectedCountry]);
            resData.result.forEach((element) => {
              this.countryList.push(element);
            });
          } else if (resData.status == 440) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${resData.message}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
            localStorage.clear();
            this.$router.push({
              path: "/login",
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${resData.message}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
          }
          this.loading = false;
          this.disabled = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.loading = false;
          this.disabled = false;
          this.$Progress.finish();
          //Swal.fire("Error", error, "error");
          Swal.fire({
            icon: "error",
            title: "Oops...",
            html: `${error}.`,
            confirmButtonColor: "#222",
            confirmButtonText: this.$t("siteLang.Done"),
          });
        });
    },
    getReseller() {
      console.log("getReseller")
      this.$Progress.start();
      this.loading = true
      this.disabled = true
      var bodyFormData = new FormData();
      bodyFormData.append("accessToken", this.accessToken);
      bodyFormData.append("accessUsername", this.accessUsername);
      bodyFormData.append("page", "1");
      bodyFormData.append("limit", "999999");
      bodyFormData.append("resellerId", (this.account_type==='admin') ? "" : "all", );
      bodyFormData.append("searchReseller", "")
      axios({
        method: "post",
        url: appConfig.APIHostAdmin + 'controller/admin/getAllResellerList',
        data: bodyFormData,
        headers: {
          "Content-Type": "multipart/form-data"
        },
      }).then((response) => {
        var resData = response.data;
        if (resData.status == 200) {
            this.reseller = [ResellerDefault]
            this.selectedReseller = ResellerDefault
            if (this.account_type=='admin'){
              this.reseller=[AllAccountDefault]
              this.selectedReseller = this.reseller[0]
            }
            if (this.account_type=='reseller'){
              this.reseller=[resData.current_account]
              this.selectedReseller = this.reseller[0]
              this.currentAccount = resData.current_account
            }
            resData.data.forEach((element) => {
              //remove z because z don't have merchant account one
              if (element.account_username!==this.defaultName){
                this.reseller.push(element);
              }
              console.log(element.account_db_code, this.$route.query.rid, this.$route.params.resellerid)
              if (element.account_db_code==(this.$route.query.rid || this.$route.params.resellerid)){
                this.selectedReseller = element
              }
            });
            this.getMerchantContract()

        } else if (resData.status == 440) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            html: `${resData.message}.`,
            confirmButtonColor: '#222',
            confirmButtonText: this.$t('siteLang.Done'),
          })
          localStorage.clear();
          this.$router.push({
            path: "/login",
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            html: `${resData.message}.`,
            confirmButtonColor: '#222',
            confirmButtonText: this.$t('siteLang.Done'),
          })
          this.loading = false;
          this.disabled = false
        }
        this.$Progress.finish();
      }).catch((error) => {
        this.loading = false;
        this.disabled = false
        this.$Progress.finish();
        //Swal.fire("Error", error, "error");
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          html: `${error}.`,
          confirmButtonColor: '#222',
          confirmButtonText: this.$t('siteLang.Done'),
        })
      });
    },
    getMerchantContract() {
      console.log("getMerchantContract");
      this.$Progress.start();
      this.loading = true;
      this.disabled = true;
      var bodyFormData = new FormData();
      bodyFormData.append("accessToken", this.accessToken);
      bodyFormData.append("accessUsername", this.accessUsername);
      bodyFormData.append("page", "1");
      bodyFormData.append("limit", "9999999");
      bodyFormData.append("reseller",  this.selectedReseller.account_db_code );
      bodyFormData.append("merchant", "all");
      axios({
        method: "post",
        url: appConfig.APIHostAdmin + 'controller/admin/getContractListForMerchant',
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          var resData = response.data;
          if (resData.status == 200) {
            this.merchantContract = []
            this.selectedmerchantContract = MerchantDefault
            resData.data.forEach((element) => {
              this.merchantContract.push(element);
            });
            this.handlePageChange(1);
            
          } else if (resData.status == 440) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${resData.message}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
            localStorage.clear();
            this.$router.push({
              path: "/login",
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${resData.message}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
          }
          this.loading = false;
          this.disabled = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.loading = false;
          this.disabled = false;
          this.$Progress.finish();
          //Swal.fire("Error", error, "error");
          Swal.fire({
            icon: "error",
            title: "Oops...",
            html: `${error}.`,
            confirmButtonColor: "#222",
            confirmButtonText: this.$t("siteLang.Done"),
          });
        });
    },
    notBeforeToday(date) {
      return date > new Date(new Date().setHours(0, 0, 0, 0));
    },
    DateChanged() {
      console.log("datechange" + this.date_range);
      this.handlePageChange(1);
      this.currentPage = 1;
      this.disable_date = true;
    },
    handlePageChange(current_page) {
      this.pageNum = current_page;
      this.currentPage = current_page;
      this.getData(this.pageNum, this.search_value);
      //this.exportData(1)
    },

    search() {
      this.handlePageChange(1);
      this.currentPage = 1;
    },
    async clear() {
      this.current_balance = 0;
      this.total_credit = 0;
      this.total_debit = 0;
      this.selectedCountry = CountryDefault;
      this.selectedmerchantContract = MerchantDefault;
      this.selectedBanks = BankDefault;
      this.seletedMethod = "";
      this.selectedStatus = "";
      this.searchSettlementId = "";
      this.searchMerchantItemId = "";
      this.filterType = "created_date";
      this.searchAmount = "";
      this.returnData = []
      this.search_value = "";
      this.handlePageChange(1);
      this.currentPage = 1;

      await this.reload();
      this.date_range = this.$refs.commonFunc.last30Days();
    },

    exportToExcel(Data, name, type) {
      var title = name.replace(/ /g, "_");
      var ws = window.XLSX.utils.json_to_sheet(Data);
      let objectMaxLength = [];
      for (let i = 0; i < Data.length; i++) {
        let value = Object.values(Data[i]);
        for (let j = 0; j < value.length; j++) {
          if (typeof value[j] == "number") {
            objectMaxLength[j] = 5;
          } else {
            objectMaxLength[j] =
              objectMaxLength[j] >= value[j].length
                ? parseInt(objectMaxLength[j])
                : parseInt(value[j].length) + 5;
          }
        }
      }
      var wscols = [];
      objectMaxLength.forEach((value, index) => {
        console.log(value);
        wscols.push({ width: objectMaxLength[index] });
      }),
        (ws["!cols"] = wscols);

      var wb = window.XLSX.utils.book_new();
      window.XLSX.utils.book_append_sheet(wb, ws, name);
      window.XLSX.writeFile(wb, title + "." + (type || "xlsx"));
    },
    async getData(pages, keyword) {
    

      this.$Progress.start();
      this.loading = true;
      this.disabled = true;
      this.disable_date = true;
      this.returnData = [];
      var bodyFormData = new FormData();
      bodyFormData.append("accessToken", this.accessToken);
      bodyFormData.append("accessUsername", this.accessUsername);
      bodyFormData.append("page", pages);
      bodyFormData.append("limit", this.perPage);
      bodyFormData.append("keyword", keyword);
      bodyFormData.append("merchantId", this.selectedmerchantContract.merchant_Id);
      bodyFormData.append("dateStart", this.date_range[0]);
      bodyFormData.append("dateEnd", this.date_range[1]);
      console.log(
        this.selectedmerchantContract.contract_id,
        // this.seletedMethod,
        // this.selectedStatus,
        // this.selectedCountry.id,
        // this.selectedUsers.processedById,
        // this.searchSettlementId,
        // this.searchMerchantItemId,
        // this.searchAmount,
        // this.filterType,
        this.date_range[0],
        this.date_range[1]
      );
      axios({
        method: "post",
        url:  appConfig.APIHostAdmin + 'controller/report/getAllMerchantDailyReport',
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          var resData = response.data;
          if (resData.status == 200) {
            this.returnData = [];
            this.totalData = resData.total;
            this.returnData = resData.data;

            // this.current_balance = resData.current_balance;
            // this.total_credit = resData.total_credit;
            // this.total_debit = resData.total_debit;
          }
          else if (resData.status == 440){
            Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${resData.message}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
              localStorage.clear();
              this.$router.push({
                  path: "/login",
            });
          }
          else {
              Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${resData.message}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
          }
          this.loading = false;
          this.disabled = false;
          this.disable_date = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.loading = false;
          this.disabled = false;
          this.disable_date = false;
          this.$Progress.finish();
          //Swal.fire("Error", error, "error");
          Swal.fire({
            icon: "error",
            title: "Oops...",
            html: `${error}.`,
            confirmButtonColor: "#222",
            confirmButtonText: this.$t("siteLang.Done"),
          });
        });
    },
    async exportData() {
      this.$Progress.start();
      this.disableExport = true;
      this.loadingButton = true;
      var bodyFormData = new FormData();
      bodyFormData.append("accessToken", this.accessToken);
      bodyFormData.append("accessUsername", this.accessUsername);
      bodyFormData.append("page", "1");
      bodyFormData.append("limit", "999999");
      bodyFormData.append("keyword", "");
      bodyFormData.append("merchantId", this.selectedmerchantContract.merchant_Id);
      bodyFormData.append("dateStart", this.date_range[0]);
      bodyFormData.append("dateEnd", this.date_range[1]);
      axios({
        method: "post",
        url:  appConfig.APIHostAdmin + 'controller/report/getAllMerchantDailyReport',
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          this.export_data = [];
          var resData = response.data;
          //this.totalData = resData.total;
          var exportData = resData.data;
          for (var i in exportData) {
            const listData = {
              merchant_name: `${exportData[i].merchant_name}`,
              created_date: `${exportData[i].created_date}`,
              success_rate: `${this.convertCurrencyFormat(exportData[i].order_count_of_success/exportData[i].order_count*100,true)}`,
              total_transactions: `${exportData[i].order_count}`,
              total_in_volume: `${this.convertCurrencyFormat(exportData[i].amount,true)}`,
              total_success_trx: `${exportData[i].order_count_of_success}`,
              total_success_volume: `${this.convertCurrencyFormat(exportData[i].amount_of_success,true)}`,
              total_out_trx: `${exportData[i].cash_out_count}`,
              total_out_volume: `${this.convertCurrencyFormat(exportData[i].cash_out_money,true)}`,
              total_in_charges: `${this.convertCurrencyFormat(exportData[i].amount_of_agent_fee,true)}`,
              total_out_charges: `${this.convertCurrencyFormat(exportData[i].cash_out_fee,true)}`,
              reseller_comm: `${this.convertCurrencyFormat(exportData[i].cash_out_of_agent_fee,true)}`,
            };
            this.export_data.splice(i, 0, listData);
          }
          console.log(this.export_data)
          setTimeout(()=>{
            this.disableExport = false;
            this.loadingButton = false;
            this.exportToExcel(this.export_data, "Merchant Transactions Report", "xlsx");
          }, 1200);
          this.loading = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.disableExport = false;
          this.loadingButton = false;
          this.loading = false;
          this.$Progress.finish();
          //Swal.fire("Error", error, "error");
          Swal.fire({
            icon: "error",
            title: "Oops...",
            html: `${error}.`,
            confirmButtonColor: "#222",
            confirmButtonText: this.$t("siteLang.Done"),
          });
        });
    },
    async reload() {
      try {
        await this.$nextTick(); // Wait for the component to be fully mounted

        const commonFunc = this.$refs.commonFunc;
        if (commonFunc && commonFunc.getFullData) {
          const data = await commonFunc.getFullData();
          this.account_type = data.account_type;
          this.currentMerchantInfo = data.merchant_info;
          const permission = data.modules.filter(e => e.name==='reports')
          if (permission) {
            this.create_modify_permission = permission[0].create_modify_permission==0 ? false : true; 
          } else {
            console.log('Module with name "reports" not found.');
          }
        } else {
          // Handle the case when commonFunc or getFullData is undefined
          // Perform appropriate actions or show a fallback message to the user
        }
      } catch (error) {
        // Handle the error silently without propagating it further
      }
    },
  },
};
</script>
<!-- <style scoped>
.table th{font-size: 13px !important;}
</style> -->